function Footer() {
    return (
        <footer className="footer">
            <hr className="hidden border-t border-t-[#DFDFDF]/50 md:block" />
            <div className="mx-auto w-full max-w-5xl">
                <div className="flex flex-col items-center gap-3 p-4 text-center text-xs text-text-secondary max-md:pb-5 md:items-start">
                    <div className="flex flex-col items-center gap-3 leading-none md:w-full md:flex-row md:justify-between">
                        <div>
                            <span>© Garena Games. </span>
                        </div>
                        <div className="flex flex-wrap items-center justify-center gap-x-4 gap-y-1">
                            <a
                                className="transition-opacity hover:opacity-70"
                                href="https://napthe.vn/faq"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Câu hỏi thường gặp
                            </a>
                            <div className="h-2 w-px bg-[#DFDFDF]"></div>
                            <a
                                className="transition-opacity hover:opacity-70"
                                href="https://napthe.vn/terms"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Điều khoản dịch vụ
                            </a>
                            <div className="h-2 w-px bg-[#DFDFDF]"></div>
                            <a
                                className="transition-opacity hover:opacity-70"
                                href="https://napthe.vn/privacy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Chính sách bảo mật
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
